










































import {Component, Vue} from 'vue-property-decorator';
import {
  namespace,
} from 'vuex-class';
import Company from '@/models/Company';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';

const CompanyStore = namespace('company');

@Component({
  components: {
    SideCardComponent,
    CompaniesTableComponent: () => import(
        /* webpackChunkName: "CompaniesTableComponent" */
        '@/components/company/CompanyTable.component.vue'),
    CompaniesTileComponent: () => import(
        /* webpackChunkName: "CompaniesTileComponent" */
        '@/components/company/CompanyTile.component.vue'),
    CompanyManageComponent: () => import(
        /* webpackChunkName: "CompanyManageComponent" */
        '@/components/company/CompanyManage.component.vue'),
    ExportAccountingModal: () => import (
        '@/components/shared/ExportAccounting.component.vue'),
    SearchBarComponent: () => import(
        '@/components/shared/SearchBar.component.vue'),
    ToggleButtonGroupComponent: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})
export default class CompaniesOverviewView extends Vue {

  @CompanyStore.Action('loadCompaniesAction')
  private loadCompaniesAction!: () => void;
  @CompanyStore.Getter('companies')
  private _companies!: Company[];

  get companies(): Company[] {
    return this._companies;
  }

  private showAccountingExport: boolean = false;

  private filterOptions: any[] = [];
  private selectedFilter: string = 'all';
  private searchString: string = '';

  private companyStyle: number = 0;
  private showCreateCompanyModal: boolean = false;

  public get filteredCompanies() {
    let filteredCompanies = this.companies;

    if (this.selectedFilter !== 'all') {
      if (this.selectedFilter === 'active') {
        filteredCompanies = filteredCompanies.filter((company) => company.active);
      } else {
        filteredCompanies = filteredCompanies.filter((company) => !company.active);
      }
    }
    return filteredCompanies;
  }

  public async beforeMount() {
    this.filterOptions = [
      {
        text: this.$t('GENERAL.ALL').toString(), value: 'all',
      },
      {
        text: this.$t('GENERAL.ACTIVE').toString(), value: 'active',
      },
      {
        text: this.$t('GENERAL.INACTIVE').toString(), value: 'inactive',
      },
    ];
    try {
      await this.loadCompaniesAction();
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
